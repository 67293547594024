import React, { useState, useEffect } from 'react'; // Añadido useEffect
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { useUsuario } from '../context/usuario'; // Asegúrate de importar el hook
import './Header.css';

function Header() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { usuario, setUsuario } = useUsuario(); // Obtener y establecer el usuario del contexto

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData } = await supabase
          .from('usuarios')
          .select('*')
          .eq('user_id', user.id)
          .single();
        if (userData) {
          setUsuario(userData); // Actualiza el contexto con los datos del usuario
        }
      }
    };

    fetchUserData();
  }, [setUsuario]); // Añadido setUsuario a las dependencias

  const handleCloseDrawer = () => {
    const drawerToggle = document.getElementById('my-drawer-3');
    if (drawerToggle) {
      drawerToggle.checked = false;
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    navigate('/'); // Redirigir a la página principal después de cerrar sesión
  };

  return (
    <div
      className={`background-header`}
    >
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        <div className="navbar">
          <div className="logo-container">
          <Link to="/dashboard" onClick={handleCloseDrawer} className="text-xl normal-case animate-slideDown">
              <img className="logo" src="/img/logo-tubim.png" alt="Logo Tubim" />
            </Link>
          </div>
          <div className="menu-header">
            <div className="logo-info">
              <div className="logo-empresa-container">
                <Link to="/mis-datos" onClick={handleCloseDrawer}> {/* Añadido enlace a mis-datos */}
                  <img src={usuario?.logo || 'url_imagen_grande'} alt="Logo Grande" className="logo-empresa" />
                  <img src={usuario?.url_imagen || 'url_imagen_pequeno'} alt="Logo Pequeño" className="logo-usuario" />
                </Link>
              </div>
              <div className="empresa-info text-left"> {/* Añadido 'text-left' para alinear a la izquierda */}
                <span className="nombre-empresa">{usuario?.empresa || 'cargando empresa'}</span>
                <p>usuario</p>
                <span className="email-empresa">{usuario?.email || 'cargando email'}</span>
              </div>
            </div>
          
            <div className="links-container">
              <Link to="/proyectos" onClick={handleCloseDrawer} className={location.pathname === '/proyectos' ? 'active btn-header' : 'btn-header'}>
                <img src="/img/iconos/ico-proyectos.png" alt="Proyectos Icon" className="btn-icon" />
                Proyectos
                {location.pathname === '/proyectos' ? (
                  <span className="material-symbols-outlined">stat_minus_1</span>
                ) : (
                  <span className="material-symbols-outlined">chevron_right</span>
                )}
              </Link>
              <Link to="/clientes" onClick={handleCloseDrawer} className={location.pathname === '/clientes' ? 'active btn-header' : 'btn-header'}>
                <img src="/img/iconos/ico-partners.png" alt="Partners Icon" className="btn-icon" />
                Partners
                {location.pathname === '/clientes' ? (
                  <span className="material-symbols-outlined">stat_minus_1</span>
                ) : (
                  <span className="material-symbols-outlined">chevron_right</span>
                )}
              </Link>
              <Link to="/reportes" onClick={handleCloseDrawer} className={location.pathname === '/reportes' ? 'active btn-header' : 'btn-header'}>
                <img src="/img/iconos/ico-reportes.png" alt="Reportes Icon" className="btn-icon" />
                Reportes
                {location.pathname === '/reportes' ? (
                  <span className="material-symbols-outlined">stat_minus_1</span>
                ) : (
                  <span className="material-symbols-outlined">chevron_right</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer-side z-1000">
        <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="menu p-4 w-60 min-h-full background-mm">          
          {isAuthenticated ? (
            <>
              <li><Link to="/clientes" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/clientes' ? 'active hover-underline' : 'hover-underline'}`}>Clientes</Link></li>
              <li><Link to="/proyectos" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/proyectos' ? 'active hover-underline' : 'hover-underline'}`}>Proyectos</Link></li>
              <li><Link to="/reportes" onClick={handleCloseDrawer} className={`text-left mt-4 ml-4 ${location.pathname === '/reportes' ? 'active hover-underline' : 'hover-underline'}`}>Reportes</Link></li>
              <li className="mt-auto text-left mt-4 ml-4"><button onClick={handleLogout}>Salir</button></li>
            </>
          ) : (
            <>
              <li className="mt-auto">
                <Link to="/registrate" onClick={handleCloseDrawer} className="btn-ghost-mm mt-5 mb-2">Registro</Link>
                <Link 
                  to="/ingresar" 
                  onClick={handleCloseDrawer} 
                  className={`btn btn-neutral ${location.pathname === '/ingresar' ? 'active hover-underline' : 'hover-underline'}`}
                >
                  Ingresar
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Header;
