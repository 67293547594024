import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase, supabaseAdmin } from '../../lib/supabaseClient'; // Importa los clientes

const AceptarInvitacion = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Crear usuario en Supabase Auth
      const { data: user, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      }, {
        emailRedirectTo: window.location.origin + '/ingresar'
      });

      if (signUpError) {
        throw new Error(`Error al registrarse: ${signUpError.message}`);
      }
      
      // Verificar que el usuario se haya creado correctamente
      if (!user || !user.user) {
        throw new Error('No se pudo crear el usuario.');
      }
      
      const userId = user.user.id;
      console.log(userId);

      // Activar el usuario manualmente usando el cliente de administrador
      try {
        const { data, error: rpcError } = await supabaseAdmin.rpc('confirm_user_email', {
          user_id: userId,
        });

        if (rpcError) {
          throw new Error(`Error al activar el usuario: ${rpcError.message}`);
        }

        if (data) {
          console.log('Usuario activado exitosamente');
          console.log('Datos del usuario actualizados:', data);
        } else {
          console.error('No se recibió la confirmación de la actualización del usuario.');
        }
      } catch (error) {
        console.error('Error al guardar la fecha de confirmación de email:', error);
        throw error;
      }

      // Extraer username del email
      const username = email.split('@')[0];

      console.log(username);
      console.log(email);
      // Insertar usuario en la tabla usuarios
      const { error: insertError } = await supabase
        .from('usuarios')
        .insert([{ user_id: userId, email, username }]);

      if (insertError) {
        throw new Error(`Error al insertar en la tabla usuarios: ${insertError.message}`);
      }

      // Iniciar sesión automáticamente
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        throw new Error(`Error al iniciar sesión: ${signInError.message}`);
      }

      // Redirigir al usuario a la página de inicio de sesión o dashboard
      navigate('/mis-datos');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container">
      <h2>Aceptar Invitación</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" value={email} readOnly className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" className="btn btn-primary">Aceptar Invitación</button>
      </form>
    </div>
  );
};

export default AceptarInvitacion;