import React from 'react';
import { useNavigate } from 'react-router-dom';

const EsperandoValidacion = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/ingresar');
  };

  return (
    <div className="container-principal">
      <h2 className="title-mm color-mm text-3xl font-bold mb-6 text-center">Verificación de Correo Electrónico</h2>
      <p className="text-center mb-4">
        Tu cuenta ha sido creada con éxito. Por favor, verifica tu correo electrónico para confirmar tu cuenta. No olvides revisar tu carpeta de spam.
      </p>
      <button
        onClick={handleBackToLogin}
        className="btn-azul"
      >
        Volver al Inicio de Sesión
      </button>
    </div>
  );
};

export default EsperandoValidacion;