import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useUsuario } from '../context/usuario'; // Asegúrate de importar el hook
import './MyAccount.css';


const MyAccount = () => {
  const { setUsuario } = useUsuario(); // Obtener setUsuario del contexto
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [rut, setRut] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imageUrlFija, setImageUrlFija] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoUrlFija, setLogoUrlFija] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [isUpdating, setIsUpdating] = useState(false); // Agregar estado para controlar la carga

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
          throw error;
        }
        if (user) {
          setUser(user);

          const { data: userData } = await supabase
            .from('usuarios')
            .select('*')
            .eq('user_id', user.id)
            .single();

          if (userData) {
            setUsername(userData.username);
            setBirthdate(userData.nacimiento);
            setRut(userData.rut);
            setName(userData.nombre);
            setLastName(userData.apellido);
            setCelular(userData.celular);
            setEmail(userData.email); // Establece el email desde los datos del usuario
            setImageUrl(userData.url_imagen);
            setImageUrlFija(userData.url_imagen);
            setLogoUrl(userData.logo);
            setLogoUrlFija(userData.logo);
            setEmpresa(userData.empresa);
            console.log("userData", userData);
          }

        } else {
          console.log('No user signed in');
        }
      } catch (error) {
        console.error('Error fetching user:', error.message);
      }
    };

    fetchUserData();
  }, []);


  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    if (!user) {
      console.error('No user signed in');
      return;
    }

    setIsUpdating(true); // Establecer isUpdating a true antes de la actualización

    try {
      let imageUrlToUpdate = imageUrl;
      let logoUrlToUpdate = logoUrl;

      if (image) {
        if (imageUrl !== imageUrlFija) {
          const url = new URL(imageUrlFija);
          const pathArray = url.pathname.split('/');
          const oldFilePath = pathArray.slice(pathArray.indexOf('usuarios') + 1).join('/');
          const nombreArchivo = pathArray[pathArray.length - 1]; // Obtener el nombre del archivo
          if (oldFilePath && nombreArchivo !== 'subir-imagen.jpg') { // Validar que no sea 'subir-imagen.jpg'
            const { error: removeError } = await supabase.storage.from('usuarios').remove([oldFilePath]);
            if (removeError) {
              console.error('Error al eliminar la imagen del storage:', removeError.message);
              throw removeError;
            }
            console.log('Imagen eliminada del storage', oldFilePath);
          }

          const fileExt = image.name.split('.').pop();
          const fileName = `${user.id}/${Date.now()}.${fileExt}`;
          const filePath = `${fileName}`;

          const { error: uploadError } = await supabase
            .storage
            .from('usuarios')
            .upload(filePath, image);

          if (uploadError) {
            throw uploadError;
          }

          imageUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
          setImageUrl(imageUrlToUpdate); // Actualiza la URL de la imagen aquí
        }

      }

      if (logo) {
        console.log("logo", logo);
        if (logoUrl !== logoUrlFija) {
          console.log("logoUrl", logoUrl);
          console.log("logoUrlFija", logoUrlFija);
          // Verifica si logoUrlFija no es null antes de crear un objeto URL
          if (logoUrlFija) {
            const url = new URL(logoUrlFija);
            const pathArray = url.pathname.split('/');
            const oldFilePath = pathArray.slice(pathArray.indexOf('usuarios') + 1).join('/');
            const nombreArchivo = pathArray[pathArray.length - 1];
            if (oldFilePath && nombreArchivo !== 'subir-logo.jpg') {
              const { error: removeError } = await supabase.storage.from('usuarios').remove([oldFilePath]);
              if (removeError) {
                console.error('Error al eliminar la imagen del storage:', removeError.message);
                throw removeError;
              }
            }

            const fileExt = logo.name.split('.').pop();
            const fileName = `${user.id}/${Date.now()}.${fileExt}`;
            const filePath = `${fileName}`;

            const { error: uploadError } = await supabase
              .storage
              .from('usuarios')
              .upload(filePath, logo);

            if (uploadError) {
              throw uploadError;
            }

            logoUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/${filePath}`;
            setLogoUrl(logoUrlToUpdate);
          }
        }

      }

      const updatedUserData = {
        username,
        nacimiento: birthdate,
        rut,
        nombre: name,
        apellido: lastName,
        celular: celular,
        url_imagen: imageUrlToUpdate,
        logo: logoUrlToUpdate,
        empresa,
        email: email, // Asegúrate de incluir el email aquí
      };

      const { error } = await supabase
        .from('usuarios')
        .update(updatedUserData)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error updating profile:', error.message);
      } else {
        setImageUrlFija(imageUrlToUpdate);
        setImage(null);
        setLogoUrlFija(logoUrlToUpdate);
        setLogo(null);
        // alert('Profile updated successfully'); // Eliminar el alert

        setIsUpdating(false); // Detener la carga después de actualizar

        // Actualiza el contexto con los nuevos datos
        setUsuario(updatedUserData); // Ahora setUsuario está definido
      }
    } catch (error) {
      console.error('Error updating profile:', error.message);
      setIsUpdating(false); // Asegúrate de detener la carga en caso de error
    }
  };

  const handleImageClick = () => {
    document.getElementById('imageInput').click();
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);

      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  const handleLogoClick = () => {
    document.getElementById('LogoInput').click();
  };

  const handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedLogo = e.target.files[0];
      setLogo(selectedLogo);

      const logoUrl = URL.createObjectURL(selectedLogo);
      setLogoUrl(logoUrl);
    }
  };


  return (
    <>
      <div className="container-principal">
        <div className="profile-image-container" onClick={handleImageClick}>
          <img src={imageUrl || 'img/subir-imagen.jpg'} alt="Profile" className="profile-image" />
        </div>
        <input
          type="file"
          id="imageInput"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
        <form onSubmit={handleUpdateProfile}>
          <div className="form-row">
            <div>
              <label className="label-izquierda">Nombre:</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <label className="label-izquierda">Apellido:</label>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
          </div>
          <div className="form-row">
            <div>
              <label className="label-izquierda">Teléfono:</label>
              <input type="text" value={celular} onChange={(e) => setCelular(e.target.value)} />
            </div>
            <div>
              <label className="label-izquierda">Correo electrónico:</label>
              <input type="text" value={email} readOnly />
            </div>
          </div>
          <div className="form-row">
            <div>
              <label className="label-izquierda">Fecha Nacimiento:</label>
              <input type="text" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} />
            </div>
            <div>
              <label className="label-izquierda">RUT:</label>
              <input
                type="text"
                value={rut}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[0-9kK-]*$/.test(value)) {
                    setRut(value);
                  }
                }}
              />
            </div>
          </div>

          <div className="divider mt-20"></div>

          <div className="profile-image-container" onClick={handleLogoClick}>
            <img src={logoUrl || 'img/subir-logo.jpg'} alt="Profile" className="profile-logo" />
            <label className="profile-logo-label">Logo de empresa</label>
          </div>
          <input
            type="file"
            id="LogoInput"
            style={{ display: 'none' }}
            onChange={handleLogoChange}
          />

          <div className="form-row">
            <div>
              <label className="label-izquierda">Empresa:</label>
              <input type="text" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
            </div>
          </div>

          <button className="btn-azul" type="submit" disabled={isUpdating}>
            {isUpdating ? 'Actualizando' : 'Guardar'}
            {isUpdating && <span className="loading loading-dots loading-xs"></span>} {/* Mostrar el span solo si está actualizando */}
          </button>
        </form>
      </div>
    </>
  );
};

export default MyAccount;