import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [telefono, setTelefono] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Limpiar todos los campos de entrada al cargar el componente
    setEmail('');
    setPassword('');
    setNombre('');
    setApellido('');
    setEmpresa('');
    setTelefono('');
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Mark that the form is submitting

    try {
      console.log('Iniciando registro de usuario...');
      console.log('Email:', email);
      console.log('Password:', password);

      // Register the user with Supabase
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      });

      console.log('Respuesta de signUp:', { data, signUpError });

      if (signUpError) {
        console.error('Supabase Auth Error:', signUpError);
        setError(signUpError.message);
        setIsSubmitting(false);
        return;
      }

      if (!data || !data.user) {
        console.error('No se recibió un usuario después del registro.');
        setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
        setIsSubmitting(false);
        return;
      }

      const user = data.user;
      console.log('Usuario registrado:', user);

      // Insert user details into PostgreSQL table
      const { error: insertError } = await supabase
        .from('usuarios')
        .insert([
          {
            user_id: user.id, // Assuming user.id is the Supabase user ID
            email: user.email,
            nombre,
            apellido,
            empresa,
            celular: telefono, // Cambiado de telefono a celular
            username: email.split('@')[0], // Extraer username del email
            // Add other fields as needed
          },
        ]);

      console.log('Respuesta de inserción:', { insertError });

      if (insertError) {
        console.error('Error inserting user into PostgreSQL:', insertError);
        setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
        setIsSubmitting(false);
        return;
      }

      // Set success message and clear form fields
      setMessage('Registro exitoso, por favor verifica tu correo electrónico.');
      setEmail('');
      setPassword('');
      setNombre('');
      setApellido('');
      setEmpresa('');
      setTelefono('');

      // Redirigir a la página de EsperandoValidacion
      navigate('/esperando-validacion');
    } catch (error) {
      console.error('Error en el proceso de registro:', error);
      setError('Error al registrar. Por favor, intenta de nuevo más tarde.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container-principal">
      <h2 className="title-mm color-mm text-3xl font-bold mb-6 text-center">REGISTRATE</h2>
      <form onSubmit={handleRegister} autoComplete="off">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col items-start">
            <label htmlFor="nombre" className="mb-1">Nombre:</label>
            <input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
              className="w-3/4"
              autoComplete="off" // Cambiado para evitar autocompletar
              name="nombre" // Nombre dinámico
            />
          </div>
          <div className="flex flex-col items-start">
            <label htmlFor="apellido" className="mb-1">Apellido:</label>
            <input
              id="apellido"
              type="text"
              placeholder="Apellido"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
              className="w-3/4"
              autoComplete="off" // Cambiado para evitar autocompletar
              name="apellido" // Nombre dinámico
            />
          </div>
          <div className="flex flex-col items-start">
            <label htmlFor="empresa" className="mb-1">Empresa:</label>
            <input
              id="empresa"
              type="text"
              placeholder="Empresa"
              value={empresa}
              onChange={(e) => setEmpresa(e.target.value)}
              required
              className="w-3/4"
              autoComplete="off" // Cambiado para evitar autocompletar
              name="empresa" // Nombre dinámico
            />
          </div>
          <div className="flex flex-col items-start relative">
            <label htmlFor="password" className="mb-1">Crea tu Clave:</label>
            <input
              id="password"
              type="password"
              placeholder="Crea tu Clave"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="border rounded w-full py-2 px-3  focus:outline-none focus:shadow-outline"
              autoComplete="new-password" // Cambiado para evitar autocompletar
              name="new-password" // Nombre dinámico
            />
          </div>
          <div className="flex flex-col items-start">
            <label htmlFor="telefono" className="mb-1">Teléfono:</label>
            <input
              id="telefono"
              type="text"
              placeholder="Teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              required
              className="w-3/4"
              autoComplete="off" // Cambiado para evitar autocompletar
              name="telefono" // Nombre dinámico
            />
          </div>
          <div className="flex flex-col items-start">
            <label htmlFor="email" className="mb-1">Correo electrónico:</label>
            <input
              id="email"
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="appearance-none border rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline"
              autoComplete="new-email" // Cambiado para evitar autocompletar
              name="new-email" // Nombre dinámico
            />
          </div>
        </div>
        {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
        {message && <p className="text-green-500 text-xs italic mt-4">{message}</p>}
        <button
          type="submit"
          disabled={isSubmitting}
          className="mt-20 btn-azul"
        >
          {isSubmitting ? 'Registrando...' : 'Ingresar'}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
