/* eslint-disable */
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import Home from './components/Home';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import MyAccount from './components/MyAccount';
import Proyecto from './components/proyectos/Proyecto';
import Proyectos from './components/proyectos/Proyectos';
import Reportes from './components/reportes/Reportes';
import Reporte from './components/reportes/Reporte';
import CrearReporte from './components/reportes/CrearReporte';
import CrearColaborador from './components/colaboradores/CrearColaborador';
import AceptarInvitacion from './components/colaboradores/AceptarInvitacion';
import SubirArchivo from './components/reportes/SubirArchivo';
import NuevoProyecto from './components/proyectos/NuevoProyecto';
import EsperandoValidacion from './components/EsperandoValidacion';
import Dashboard from './components/Dashboard'; // Importa el nuevo componente Dashboard
import { useUsuario } from './context/usuario'; // Importa el hook useUsuario

function App() {
	const { user, usuario, loading } = useUsuario();

	if (loading) {
		return <div>Cargando...</div>;
	}

	const handleUploadSuccess = () => {
		// console.log('Upload successful!');
	};

	return (
		<div className="App">
			<Routes>
				{/* Rutas públicas */}
				<Route path="/" element={<PublicLayout><Home /></PublicLayout>} />
				<Route path="/ingresar" element={<PublicLayout><LoginForm /></PublicLayout>} />
				<Route path="/registro" element={<PublicLayout><RegisterForm /></PublicLayout>} />
				<Route path="/aceptar-invitacion" element={<PublicLayout><AceptarInvitacion /></PublicLayout>} />
				<Route path="/esperando-validacion" element={<PublicLayout><EsperandoValidacion /></PublicLayout>} />
				{/* Otras rutas públicas */}

				{/* Rutas privadas */}
				<Route path="/mis-datos" element={user ? <PrivateLayout><MyAccount /></PrivateLayout> : <Navigate to="/" />} />
				<Route path="/proyectos" element={<PrivateLayout><Proyectos /></PrivateLayout>} />
				<Route path="/proyectos/nuevo-proyecto" element={<PrivateLayout><NuevoProyecto /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey" element={<PrivateLayout><Proyecto /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/crear-reporte" element={<PrivateLayout><CrearReporte /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/reporte/:reporteKey" element={<PrivateLayout><Reporte /></PrivateLayout>} />
				<Route path="proyecto/:proyectoKey/reporte/:reporteKey/subir-archivo" element={<PrivateLayout><SubirArchivo onUploadSuccess={handleUploadSuccess} /></PrivateLayout>} />
				<Route path="crear-colaborador" element={<PrivateLayout><CrearColaborador /></PrivateLayout>} />
				<Route path="/reportes" element={<PrivateLayout><Reportes /></PrivateLayout>} />
				<Route path="/dashboard" element={user ? <PrivateLayout><Dashboard /></PrivateLayout> : <Navigate to="/" />} />
			</Routes>
		</div>
	);
}

export default App;
/* eslint-enable */
