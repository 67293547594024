import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const NuevoProyecto = () => {
  const [formData, setFormData] = useState({
    proyecto: '',
    descripcion: '',
    fecha_inicio: '',
    fecha_termino: '',
    ubicacion: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useUsuario();
  const [isUpdating, setIsUpdating] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  // Verificar si el usuario está definido
  useEffect(() => {
    if (!user) {
      setError('Usuario no autenticado');
    } else {
      console.log(user);
    }
  }, [user]);

  useEffect(() => {
    console.log('Google Maps API Key:', process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      setFormData((prevData) => ({
        ...prevData,
        ubicacion: place.formatted_address
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    if (!user) {
      setError('Usuario no autenticado');
      return;
    }

    const proyectoData = {
      ...formData,
      key: Date.now(),
      creador: user.id
    };

    const { error: proyectoError } = await supabase
      .from('proyectos')
      .insert([proyectoData]);

    if (proyectoError) {
      console.log('Error al crear el proyecto:', proyectoError);
      setError('Error al crear el proyecto');
      return;
    }

    const colaboradorData = {
      proyecto_key: proyectoData.key,
      usuario_id: user.id,
      perfil: 'admin'
    };

    const { error: colaboradorError } = await supabase
      .from('colaboradores')
      .insert([colaboradorData]);

    if (colaboradorError) {
      console.log('Error al crear el colaborador:', colaboradorError);
      setError('Error al crear el colaborador');
      return;
    }

    navigate('/proyectos');
    setIsUpdating(false);
  };

  const handleCancel = () => {
    navigate('/proyectos');
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <>
        <h2 className='text-2xl font-bold mt-20'>Nuevo Proyecto</h2>
        <div className='container-mayor'>
          <span className="material-symbols-outlined ico-cerrar-form" onClick={handleCancel}>
            cancel
          </span>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <form onSubmit={handleSubmit} className="form-container">
            <div className="flex mb-10">
              <div className="image-upload w-1/3 flex flex-col items-center">
                <img src={'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/subir-logo.jpg'} alt="Imagen del proyecto" className="project-image" />
                <label className='text-xs'>Imagen del proyecto</label>
                <input type="file" accept="image/*" id="imageInput" style={{ display: 'none' }} />
              </div>
              <div className="description-container w-2/3">
                <label className="text-left">Descripción del proyecto:</label>
                <textarea name="descripcion" value={formData.descripcion} onChange={handleChange} className="w-full" />
              </div>
            </div>
            <div className="form-row">
              <div>
                <label className="text-left">Nombre Proyecto:</label>
                <input type="text" name="proyecto" value={formData.proyecto} onChange={handleChange} required />
              </div>

              <div>
                <label className="text-left">Ubicación:</label>
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    componentRestrictions: { country: 'CL' } // Limitar a Chile
                  }}
                >
                  <input type="text" name="ubicacion" value={formData.ubicacion} onChange={handleChange} required />
                </Autocomplete>
              </div>

            </div>

            <div className="form-row">
              <div>
                <label className="text-left">Fecha de Inicio:</label>
                <input type="date" name="fecha_inicio" value={formData.fecha_inicio} onChange={handleChange} required className="w-full h-10 rounded p-2" />
              </div>
              <div>
                <label className="text-left">Fecha de Término:</label>
                <input type="date" name="fecha_termino" value={formData.fecha_termino} onChange={handleChange} required className="w-full h-10 rounded p-2" />
              </div>
            </div>
            <div className="form-row">
              <div>
                <label className="text-left">Nombre Encargado:</label>
                <input type="text" name="encargado" onChange={handleChange} required />
              </div>
              <div>
                <label className="text-left">Correo Contacto:</label>
                <input type="email" name="correo" onChange={handleChange} required className="w-full h-10 rounded p-2" />
              </div>
            </div>

            <div>
          
              <p className="mt-10 font-bold">AGREGA EL LINK DE TU CARPETA DE PROYECTO</p>
              <p className="text-xs">Google Drive, Dropbox, OneDrive, ETC...</p>
              <br />
              <input type="url" name="link" onChange={handleChange} required className="w-80 h-10 rounded p-2" />
            </div>
            <button className="btn-azul" type="submit" disabled={isUpdating}>
              {isUpdating ? 'Actualizando' : 'Guardar'}
              {isUpdating && <span className="loading loading-dots loading-xs"></span>} {/* Mostrar el span solo si está actualizando */}
            </button>
          </form>
        </div>
      </>
    </LoadScript>
  );
};

export default NuevoProyecto;