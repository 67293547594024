import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';

const CrearReporte = () => {
  const { proyectoKey } = useParams(); // Asegúrate de que 'key' se obtiene correctamente
  const navigate = useNavigate();
  const [reporte, setReporte] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log('Proyecto Key:', proyectoKey); // Verificar que la clave del proyecto se obtiene correctamente
  }, [proyectoKey]);

  const generateKey = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    const reporteKey = generateKey();

    const { error } = await supabase
      .from('reportes')
      .insert([
        { reporte, descripcion, proyecto_key: proyectoKey, key: reporteKey }
      ]);

    if (error) {
      setError(error.message);
    } else {
      setSuccess(true);
      setReporte('');
      setDescripcion('');
      navigate(`/proyecto/${proyectoKey}`);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Crear Nuevo Reporte</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">Reporte creado exitosamente!</div>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reporte">
            Reporte
          </label>
          <input
            type="text"
            id="reporte"
            value={reporte}
            onChange={(e) => setReporte(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcion">
            Descripción
          </label>
          <textarea
            id="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            // Eliminar el atributo 'required' para que la descripción no sea obligatoria
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Crear Reporte
          </button>
        </div>
      </form>
    </div>
  );
};

export default CrearReporte;