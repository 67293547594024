import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import ChatProyecto from './ChatProyecto'; // Importa el componente de chat
import { useUsuario } from '../../context/usuario'; // Importa el hook del contexto de usuario

const Proyecto = () => {
  const { clienteRut, proyectoKey } = useParams();
  const { usuario, loading: usuarioLoading } = useUsuario(); // Usa el contexto de usuario
  const [proyecto, setProyecto] = useState(null);
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProyectoAndReportes = async () => {
      try {
        // Fetch project details
        const { data: proyectoData, error: proyectoError } = await supabase
          .from('proyectos')
          .select('*')
          .eq('key', proyectoKey)
          .single();

        if (proyectoError) {
          setError(proyectoError.message);
          setLoading(false);
          return;
        }

        setProyecto(proyectoData);

        // Fetch reports associated with the project
        const { data: reportesData, error: reportesError } = await supabase
          .from('reportes')
          .select('*')
          .eq('proyecto_key', proyectoKey);

        if (reportesError) {
          setError(reportesError.message);
          setLoading(false);
          return;
        }

        // Fetch number of links for each reporte
        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: linksData, error: linksError } = await supabase
            .from('links')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (linksError) {
            setError(linksError.message);
            setLoading(false);
            return;
          }

          reporte.links_count = linksData.length;
        }

        // Fetch number of archivos for each reporte
        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: archivosData, error: archivosError } = await supabase
            .from('archivos')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (archivosError) {
            setError(archivosError.message);
            setLoading(false);
            return;
          }

          reporte.archivos_count = archivosData.length;
        }

        setReportes(reportesData);
      } catch (error) {
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    if (proyectoKey) {
      fetchProyectoAndReportes();
    } else {
      setLoading(false);
      setError('proyectoKey is undefined');
    }
  }, [clienteRut, proyectoKey]);

  if (loading || usuarioLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      {proyecto && (
        <div className="mb-6">
          <h1 className="text-3xl font-bold">{proyecto.proyecto}</h1>
          <p><strong>Descripción:</strong> {proyecto.descripcion}</p>
          <p><strong>Fecha de Inicio:</strong> {proyecto.fecha_inicio}</p>
          <p><strong>Fecha de Término:</strong> {proyecto.fecha_termino}</p>
        </div>
      )}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">Reportes</h1>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded ml-auto"
          onClick={() => navigate(`/proyecto/${proyectoKey}/crear-reporte`)}
        >
          Nuevo Reporte
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {reportes.map((reporte) => (
          <div key={reporte.id} className="bg-white p-4 border rounded shadow">
            <h2 className="text-xl font-bold">
              <Link to={`/proyecto/${proyectoKey}/reporte/${reporte.key}`}>{reporte.reporte}</Link>
            </h2>
            <p><strong>Fecha de Creación:</strong> {new Date(reporte.fecha_creacion).toLocaleString()}</p>
            <p><strong>Descripción:</strong> {reporte.descripcion}</p>
            <p><strong>Cantidad de Links:</strong> {reporte.links_count}</p>
            <p><strong>Cantidad de Archivos:</strong> {reporte.archivos_count}</p>
          </div>
        ))}
      </div>
      <ChatProyecto 
        proyectoKey={proyectoKey} 
        usuario={usuario?.user_id} 
        urlImagen={usuario?.url_imagen} // Pasa la URL de la imagen del usuario
        nombre={usuario?.nombre} // Pasa el nombre del usuario
        apellido={usuario?.apellido} // Pasa el apellido del usuario
      /> {/* Agrega el componente de chat */}
    </div>
  );
};

export default Proyecto;
