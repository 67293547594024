import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Importar useNavigate
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';

const Proyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // Estado para el orden
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const { user } = useUsuario(); // Obtener el usuario desde el contexto
  const navigate = useNavigate(); // Inicializar useNavigate

  useEffect(() => {
    const fetchProyectos = async () => {
      try {
        // Obtener los clientes a los que el usuario tiene acceso
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('proyecto_key')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const proyectosKeys = colaboradoresData.map(colaborador => colaborador.proyecto_key);

        // Obtener los proyectos de los clientes a los que el usuario tiene acceso
        const { data: proyectosData, error: proyectosError } = await supabase
          .from('proyectos')
          .select('*')
          .in('key', proyectosKeys);

        if (proyectosError) {
          setError(proyectosError.message);
          setLoading(false);
          return;
        }

        const promises = proyectosData.map(async (proyecto) => {

          const { data: reportesData, error: reportesError } = await supabase
            .from('reportes')
            .select('id')
            .eq('proyecto_key', proyecto.key);

          if (reportesError) {
            throw reportesError;
          }

          return {
            ...proyecto,
            reportCount: reportesData.length, // Asegurando que reportesData sea un array
          };
        });

        const proyectosWithClientes = await Promise.all(promises);

        setProyectos(proyectosWithClientes);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (user) {
      fetchProyectos();
    }
  }, [user]);

  const sortedProyectos = [...proyectos].sort((a, b) => {
    return sortOrder === 'asc' ? a.reportCount - b.reportCount : b.reportCount - a.reportCount;
  });

  const filteredProyectos = sortedProyectos.filter((proyecto) => {
    return (
      proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proyecto.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-20">
      {proyectos.length > 0 ? (
        <>
          <h1 className="text-3xl font-bold mb-4">Lista de Proyectos</h1>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Buscar proyecto"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <button
              onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Ordenar por cantidad de reportes ({sortOrder === 'asc' ? 'Ascendente' : 'Descendente'})
            </button>
          </div>
        </>
      ) : (
        <h1 className="text-3xl font-bold mb-4">Aún no tienes proyectos</h1>
      )}
      <div className="mb-4">
        <button
          onClick={() => navigate('/proyectos/nuevo-proyecto')} // Navegar a NuevoProyecto
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Crear Nuevo Proyecto
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProyectos.map((proyecto) => (
          <div key={proyecto.id} className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">
              <Link to={`/proyecto/${proyecto.key}`} className="text-blue-500 hover:underline">
                {proyecto.proyecto}
              </Link>
            </h2>
            <p className="mb-2">
              <Link to={`/proyecto/${proyecto.key}`} className="text-blue-500 hover:underline">
                {proyecto.descripcion}
              </Link>
            </p>
            <p className="mb-2">Cantidad de Reportes: {proyecto.reportCount}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Proyectos;
